<template>
  <div v-if="showFacebookChat">
    <div id="testFacebookChat"></div>
    <div id="fb-root"></div>
    <!-- Your Wtyczka czatu code -->
    <div id="fb-customer-chat" class="fb-customerchat" :page_id="pageId" :attribution="attribution">
    </div>
  </div>
</template>



<script>
import {getLandingPageSettings} from "@/scripts/settings";

export default {
  data() {
    return {
      showFacebookChat: false,
      pageId: '',
      attribution: '',
    };
  },
  mounted() {
    this.fetchSettings();
  },
  methods: {
    fetchSettings(){
      getLandingPageSettings(this.$route.params.garden).then((settings) => {
        if (settings.facebook && settings.facebook.showFacebookChat) {
          this.showFacebookChat = settings.facebook.showFacebookChat || false ;
          this.pageId = settings.facebook.pageId || "" ;
          this.attribution = settings.facebook.attribution ||"" ;
          this.script(document, 'script', 'fb-customer-chat-jssdk');
        }
      });
    },
    fbAsyncInit () {
      FB.init({
        xfbml : true,
        version : 'v18.0'
      });
    },
    script (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      this.root(d, 'fb-root');
      if (!window.fbAsyncInit) {
        window.fbAsyncInit = this.fbAsyncInit;
      }
      js = d.createElement(s); js.id = id;
      js.async = js.defer = true;
      js.src = 'https://connect.facebook.net/pl_PL/sdk/xfbml.customerchat.js';
      fjs.parentNode.insertBefore(js, fjs);
    },
    root (d, id) {
      if (d.getElementById(id)) { return; }
      var el = d.createElement('div'); el.id = id;
      d.body.appendChild(el)
    }
  },
};
</script>


<style scoped lang="scss">

</style>