<template>
  <footer class="footer-area footer-style-01">
    <div class="im-call-to-action-area ptb--70 im-separator">
      <Contact />
    </div>
    <div class="footer-wrapper ptb--70">
      <v-container class="container">
        <v-row>
          <v-col lg="3" md="3" sm="6" cols="12">
            <div class="footer-logo">
              <a href="/"><img :src="logoFooter" alt="brand image"/></a>
            </div>
          </v-col>
          <v-col lg="9" md="9" sm="6" cols="12" class="">
            <div class="footer-link">
              <h4>{{ $t('footerGardens.contact.header') }}</h4>
              <v-row>
                <v-col lg="4" md="4" sm="12" cols="12" class="">
                  <div v-html="$t('footerGardens.contact.contentParagraph1')" class="white--text"></div>
                </v-col>
                <v-col lg="4" md="4" sm="12" cols="12" class="">
                  <div v-html="$t('footerGardens.contact.contentParagraph2')" class="white--text"></div>
                </v-col>
                <v-col lg="4" md="4" sm="12" cols="12" class="">
                  <div v-html="$t('footerGardens.contact.contentParagraph3')" class="white--text"></div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt--20">
            <div class="white--text col-sm-12">
              Copyright © {{ new Date().getFullYear() }} {{ $t('copyrightText') }}
            </div>
        </v-row>
      </v-container>
    </div>
  </footer>
</template>

<script>
  import Contact from "@/components/garden-of-lights/Contact.vue";
  import {getLandingPageSettings} from "@/scripts/settings";
  import feather from "feather-icons";

  export default {
    components: {Contact},
    data() {
      return {
        logoFooter: null,
      };
    },
    created() {
      this.fetchSettings();
    },
    methods: {
      fetchSettings(){
        let landigpageName = "corporate";
        getLandingPageSettings(landigpageName).then((settings) => {
          this.logoFooter = settings.logo.logoFooter;
        });
      },
    },
  };
</script>
