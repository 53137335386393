<template>
  <div class="portfolio-area pb--120 " id="portfolio">
    <div class="portfolio-sacousel-inner pb--30" v-if="!isLoading">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="text-center section-title mb_sm--0">
              <span class="subtitle2">{{ $t('headers-top-text.portfolio')  }}</span>
              <h2 class="heading-title">{{ $t('headers.portfolio')  }}</h2>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div>
        <!-- Start Portfolio Activation  -->
        <VueSlickCarousel
            v-bind="settings"
            class="rn-slick-activation portfolio-slick-activation item-fluid slick-dotted rn-slick-dot mt--40 mt_sm--40"
            v-if="$t('portfolioContent').length">
          <!-- Start Single Portfolio  -->
          <div class="im_portfolio" v-for="(item, i) in $t('portfolioContent')" :key="i">
            <div class="thumbnail_inner">
              <div class="thumbnail">
                <a :href="item.url">
                  <img class="w-100" :src="item.src" :alt="item.url" />
                </a>
              </div>
            </div>
            <div class="content">
              <div class="inner">
                <div class="portfolio_heading">
                </div>
                <div class="portfolio_hover">
                </div>
              </div>
            </div>
            <a :href="item.url"
               class="transparent_link"
            ></a>
          </div>
          <!-- End Single Portfolio  -->
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import {getLandingPageSettings} from "@/scripts/settings";
export default {
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      isLoading:true,
      // for all works
      settings: {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
              arrows: true,
            },
          },
          {
            breakpoint: 1263,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
              arrows: true,
            },
          },
          {
            breakpoint: 770,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1,
              arrows: true,
            },
          },
          {
            breakpoint: 599,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1,
              arrows: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.fetchSettings();
  },
  methods: {
    fetchSettings(){
      getLandingPageSettings(this.$route.params.garden).then((settings) => {
      });
    },
  },watch: {
    '$i18n.messages': {
      handler: function() {
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
        },
      deep: true,
          immediate: true
    }
  }
};
</script>