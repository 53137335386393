<template>
  <div>
    <FacebookChat />

    <!-- Start Header Area -->
    <HeaderLandingPage />
    <!-- End Header Area -->

    <!-- Start Banner Area  -->
    <Banner />
    <!-- End Banner Area  -->

    <!-- Start Service Area  -->
    <Service v-on:openModal="openModal" v-if="activateServiceSection" />
    <!-- End Service Area  -->

    <!-- Start Pricing Plan Area  -->
    <Pricing v-on:openModal="openModal" v-if="activatePricingSection" />
    <!-- End Pricing Plan Area  -->

    <!-- Start Planning Area  -->
    <Plan v-on:openModal="openModal" v-if="activatePlanSection" />
    <!-- End Planning Area  -->

    <!-- Start Map Area -->
    <Map v-if="activateMapSection" />
    <!-- End Map Area -->

    <!-- Start YouTubeVideo Area -->
    <YouTubeVideo v-if="activateVideoSection" />
    <!-- End YouTubeVideo Area -->

    <!-- Start Testimonnials Area -->
    <Testimonials />
    <!-- End Testimonnials Area -->

    <!-- Start Gallery Area  -->
    <Gallery v-if="activateAttractionsSection" />
    <!-- End Gallery Area  -->

    <!-- Start FAQ Area  -->
    <Faq v-if="activateFAQSection" />
    <!-- End FAQ Area  -->

    <!-- Start Portfolio Area -->
    <Portfolio v-if="activateOtherGardensSection" />
    <!-- End Portfolio Area -->

    <!-- Start Modal Area  -->
    <v-dialog
        v-model="dialog"
        activator="parent">
      <v-card>
        <v-card-title>
          <div class="modal-title">{{dialogTitle}}</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false" >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p class="description text-center" v-html="dialogText"></p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End Modal Area  -->

    <!-- Popup Modal Area  -->
    <v-dialog
        v-model="popup"
        activator="parent">
      <v-card class="popup">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="popup = false" >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p class="description text-center" v-html="popupContent"></p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Popup Modal Area  -->

    <!-- Start Footer Area  -->
    <Footer id="contact" />
    <!-- End Footer Area  -->
  </div>
</template>

<script>
import FacebookChat from "../components/garden-of-lights/FacebookChat";
import HeaderLandingPage from "../components/garden-of-lights/HeaderLandingPage";
import Banner from "@/components/garden-of-lights/Banner.vue";
import ServiceThree from "@/components/service/ServiceThree.vue";
import Footer from "@/components/garden-of-lights/Footer.vue";
import Service from "@/components/garden-of-lights/Service.vue";
import Plan from "@/components/garden-of-lights/Plan.vue";
import Map from "@/components/garden-of-lights/Map.vue";
import Pricing from "@/components/garden-of-lights/Pricing.vue";
import Counter from "@/components/garden-of-lights/Counter.vue";
import Faq from "@/components/garden-of-lights/Faq.vue";
import Portfolio from "@/components/garden-of-lights/Portfolio.vue";
import Gallery from "@/components/garden-of-lights/Gallery.vue";
import SocialIconsBar from "@/components/garden-of-lights/SocialIconsBar.vue";
import {getLandingPageSettings} from "@/scripts/settings";
import YouTubeVideo from "@/components/garden-of-lights/YouTubeVideo.vue";
import Testimonials from "@/components/garden-of-lights/Testimonials.vue";

export default {
  components: {
    Testimonials,
    YouTubeVideo,
    SocialIconsBar,
    Banner,
    Portfolio,
    Faq,
    Counter,
    Pricing,
    Plan,
    Service,
    Footer,
    ServiceThree,
    FacebookChat,
    HeaderLandingPage,
    Map,
    Gallery
  },
  data() {
    return {
      dialog: false,
      popup: false,
      dialogText: "",
      dialogTitle: "",
      popupContent: "",
      showMoreDesc: false,
      activateOtherGardensSection: true,
      activateMapSection: true,
      activatePricingSection: true,
      activateAttractionsSection: true,
      activateFAQSection: false,
      activateServiceSection: false,
      activatePlanSection: false,
      activateSliderSection: false,
      activateVideoSection: false
    };
  },
  mounted() {
    this.fetchSettings();
    //document.title = this.$t(`meta.title`);
  },
  methods: {
    showMoreDescClick(){
      this.showMoreDesc = true;
    },
    openModal(title, message){
      this.dialogTitle = title;
      this.dialogText = message;
      this.dialog = true;
    },
    showPopuUp(settings){
      if(settings.popUp.active==true){
        var lastTimeShowed = sessionStorage.getItem("popup");
        var check = new Date();
        var from = new Date(settings.popUp.periodStart);
        var to   = new Date(settings.popUp.periodEnd);

        if(lastTimeShowed!=null && lastTimeShowed==check.toDateString()){
          //already showed today
        }
        else if(check >= from && check <= to){
          this.popupContent = this.$t('popup.content');
          this.popup = true;
          sessionStorage.setItem("popup", check.toDateString());
        }
      }
    },
    fetchSettings(){
      getLandingPageSettings(this.$route.params.garden).then((settings) => {
        this.activateOtherGardensSection = settings.activateOtherGardensSection;
        this.activateMapSection = settings.activateMapSection;
        this.activatePricingSection = settings.activatePricingSection;
        this.activateAttractionsSection = settings.activateAttractionsSection;
        this.activateSliderSection = settings.activateSliderSection;
        this.activateCounterSection = settings.activateCounterSection;
        this.activateFAQSection = settings.activateFAQSection;
        this.activatePlanSection = settings.activatePlanSection;
        this.activateServiceSection = settings.activateServiceSection;
        this.activateVideoSection = settings.activateVideoSection;

        this.showPopuUp(settings);
      });
    },
  },
};

</script>

<style lang="scss">
@media only screen and (min-width: 1264px) and (max-width: 1903px) {
  .slider-video-bg.slide.slide-style-2 {
    padding-top: 40px;
    padding-bottom: 160px;
    min-height: auto;
  }
}
@media only screen and (min-width: 1260px)  {
  .v-dialog {
    width: 800px !important;
  }
}
.v-card__text{
  padding-top: 20px !important;
}
@media only screen and (max-width: 767px){

}

p br {
  display: inline !important;
}
</style>
