import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import {loadLanguageAsync} from '../i18n/setup.js'
import {getLandingPageSettings} from '../scripts/settings.js'
import Landingpage from "../views/Landingpage.vue";
import Gardens from "../views/Gardens.vue";
import Corporate from "../views/Corporate.vue";
import Missing from '../views/404.vue';


Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Corporate',
    props: true,
    component: Corporate,
    meta: {
        language: 'en',
        site: 'corporate',
        hasQueryParamPage: true,
    },
},
    {
        path: '/:lang(en)',
        name: 'Corporate',
        redirect: '/'
    },
    {
        path: '/:lang([a-zA-Z]+)/404',
        name: "404",
        component: Missing,
        props: true,
        meta: {
            hasQueryParamPage: true,
        },
    },
    {
        path: '/:lang([a-zA-Z]+)',
        name: 'Corporate',
        props: true,
        meta: {
            site: 'corporate',
            hasQueryParamPage: true,
        },
        component: Corporate
    },
    {
        path: '/:lang([a-zA-Z]+)/:landingpage(poland)',
        redirect: '/:lang'
    },
    {
        path: '/:lang([a-zA-Z]+)/:garden([a-zA-Z0-9\\-]+)',
        component: Landingpage,
        name: "LandingPage",
        props: true,
        meta: {
            hasQueryParamPage: true,
        },
    },
    {
        path: '/:lang([a-zA-Z]+)/:garden([a-zA-Z0-9\\-]+)/*',
        component: Landingpage,
        name: "LandingPage",
        props: true,
        meta: {
            hasQueryParamPage: true,
        },
    },
    {
        path: '*',
        name: "404",
        component: Missing,
        props: true,
        meta: {
            hasQueryParamPage: true,
            title: '404'
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0
        if (to.hash && document.getElementById(to.hash)) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        } else {
            return;
        }

        return goTo(scrollTo)
    },
})

router.beforeEach((to, from, next) => {
    if (to.name === '404') {
        getLandingPageSettings('corporate').then((settings) => {
            if (!settings.languages.available.includes(to.params.lang)) {
                let path = to.fullPath.replace(to.params.lang, settings.languages.default);
                router.push(path);
                return;
            }
            loadLanguageAsync(to.params.lang, 'corporate').then(() => next());
        }).catch((e) => {
            if(to.fullPath!=='/en/404'){
                router.push('/en/404');
            }
        });
        return;
    }

    if (!(to.params.lang) && to.meta.language) {
        if(to.meta.site ==='corporate'){
            let userlang = navigator.language
                || navigator.userLanguage;
            if (sessionStorage.getItem('site-visited')) {
                to.params.lang = 'en';
            }else{
                sessionStorage.setItem('site-visited','true');
                if (userlang === 'pl' || userlang === 'pl-PL') {
                    router.push('/pl');
                    return;
                }
            }
        }
    }
    if (!(to.params.lang)) {
        router.push('/')
        return;
    }

    if (to.params.garden) {
        getLandingPageSettings(to.params.garden).then((settings) => {
            if (settings && typeof settings.enabled!=='undefined') {
                if(settings.enabled === false){
                    router.push('/'+to.params.lang)
                }
            }
            if (!settings.languages.available.includes(to.params.lang)) {
                let path = to.fullPath.replace(to.params.lang, settings.languages.default);
                router.push(path);
                return;
            }
            loadLanguageAsync(to.params.lang, to.params.garden).then(() => next());
        }).catch((e) => {
            router.push('/en/404')
        });
    } else if (to.params.landingpage) {
        getLandingPageSettings(to.params.landingpage).then((settings) => {
            if (!settings.languages.available.includes(to.params.lang)) {
                let path = to.fullPath.replace(to.params.lang, settings.languages.default);
                router.push(path);
                return;
            }
            loadLanguageAsync(to.params.lang, to.params.landingpage).then(() => next());
        }).catch((e) => {
            router.push('/en/404')
        });
    } else {
        //corporate?
        getLandingPageSettings('corporate').then((settings) => {
            if (!settings.languages.available.includes(to.params.lang)) {
                let path = to.fullPath.replace(to.params.lang, settings.languages.default);
                router.push(path);
                return;
            }
            loadLanguageAsync(to.params.lang, 'corporate').then(() => next());
        }).catch((e) => {
            router.push('/');
        });
    }

})

export default router