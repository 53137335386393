
import http from "axios";

export  async function getLandingPageSettings(landigpageName, cache = false){
    if(!landigpageName){
        return null;
    }
    let settings;
    let minutesInStorage = 60;   //todo zmienic na ilosc minut po jakim czyscimy konfiguracje na produkcji
    try {
        settings = JSON.parse(sessionStorage.getItem(`settings-${landigpageName}`));
    } catch (e) {
    } finally {
    }
    // console.log(settings.time);
    if((cache && settings)|| (settings && settings.time && settings.time>(Date.now()-(minutesInStorage*60000))) ){
        return settings;
    }
    let garden = 'gardens/';
    if(landigpageName === 'corporate' || landigpageName === 'poland'){
        garden = '';
    }
    // console.log('downloading2');
    let response = await http.get(`/settings/${garden}${landigpageName}.json?v=1.0`)
    settings = response.data;
    //todo if not exist
    settings.time = Date.now();
    sessionStorage.setItem(`settings-${landigpageName}`,JSON.stringify(settings))
    // console.log('downloaded2');
    return settings;
}
export  async function getLandingPageLanguages(landingpageName, language){

    let messages;
    let minutesInStorage = 0;   //todo zmienic na ilosc minut po jakim czyscimy konfiguracje na produkcji
    try {
        messages = JSON.parse(sessionStorage.getItem(`languages-${landingpageName}-${language}`));
    } catch (e) {
    } finally {
    }
    // console.log(settings.time);
    if(messages && messages.time && messages.time>(Date.now()-(minutesInStorage*60000)) ){
        // console.log('from storage');
        return messages;
    }
    let response = await http.get(`/i18n/${garden}${landingpageName}/${language}.json`)
    messages = response.data;
    //todo if not exist
    messages.time = Date.now();
    sessionStorage.setItem(`languages-${landingpageName}-${language}`,JSON.stringify(messages))
    return messages;
}