<template>
  <div class="service-area creative-service-wrapper pt--90 pb--160">
    <v-container>
      <v-row class="service-one-wrapper text-center" v-if="this.serviceContent[0]">

        <v-col
            xl="4"
            lg="4"
            md="4"
            sm="12"
            cols="12"
            class="mt--30"
            key="service0">
            <div class="service service__style--1" >
              <a @click="sendEventToParent(0)">
                <div class="content mt--20">
                  <h3 class="heading-title">
                    {{ $t('service.' + this.serviceContent[0].id + '.title')  }}
                  </h3>
                  <p style="margin-bottom: 15px" v-html="$t('service.' + this.serviceContent[0].id + '.description')" />
                  <p>
                    <a @click="sendEventToParent(0)" class="link_underline map_link" >{{$t('buttons.seeOnMap')}}</a>
                    <img src="/images/pin-yellow.svg" style="vertical-align: middle; margin-left: 5px" />
                  </p>
                </div>
              </a>
            </div>
        </v-col>

        <v-col
            xl="4"
            lg="4"
            md="4"
            sm="12"
            cols="12"
            class="mt--30"
            key="service1">
          <div class="service service__style--1">
              <div class="content mt--20">
                <h3 class="heading-title">
                  {{ $t('service.' + this.serviceContent[1].id + '.title')  }}
                </h3>
                <p>{{currentDateText}}<br/><span class="openingStatusText">{{isItOpenTodayText}}</span><br/>{{openingHoursTodayText}}</p>
              </div>
          </div>
        </v-col>
        <v-col
            xl="4"
            lg="4"
            md="4"
            sm="12"
            cols="12"
            class="mt--30"
            key="service2">
            <div class="service service__style--1">

                <a v-if="this.serviceContent[2].hasDescriptionExtended" @click="sendEventToParent(2)">
                  <div class="content mt--20">
                    <h3 class="heading-title">
                      {{ $t('service.' + this.serviceContent[2].id + '.title')  }}
                    </h3>
                    <p v-html="$t('service.' + this.serviceContent[2].id + '.description')" />
                  </div>
                </a>
                <div v-else class="content mt--20">
                  <h3 class="heading-title">
                    {{ $t('service.' + this.serviceContent[2].id + '.title')  }}
                  </h3>
                  <p v-html="$t('service.' + this.serviceContent[2].id + '.description')" />
                </div>
                <p  v-if="this.serviceContent[2].hasDescriptionExtended" class="content mt--20"><a class="link_underline map_link mt-3" @click="sendEventToParent(2)">{{ $t('buttons.seeMOreInfo')  }}</a></p>
            </div>
        </v-col>
      </v-row>
      <v-row>
      <v-col lg="12" class="mission row pt--90 hidden-md-and-down" >
        <div class="col-md-6 col-lg-6 col-xl-6 col-6">
          <div class="mb--30"><h2 v-html="$t('mission.header')" class="text-left mb--30" /></div>
          <div v-html="$t('mission.lead')" class="text-justify" />
          <!--<div v-html="$t('mission.restOfText')" class="text-justify" />-->
        </div>
        <div class="col-md-6 col-lg-6 col-xl-6 col-6 text-center">
          <img :src="$t('mission.img')" />
        </div>
      </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import {getLandingPageSettings} from "@/scripts/settings";

export default {
  data() {
    return {
      serviceContent: [],
      openingStateSettings: null,
      currentDateText: "",
      isItOpenTodayText: "",
      openingHoursTodayText: "",
      dateFormat: "Europe"
    };
  },
  created() {
    this.fetchSettings();
  },
  methods: {
    sendEventToParent(id){
      this.$emit("openModal", this.$t('service.' + this.serviceContent[id].id + '.titleExtended'), this.$t('service.' + this.serviceContent[id].id + '.descriptionExtended'));
    },
    test(){
      alert('test');
    },
    fetchSettings(){
      getLandingPageSettings(this.$route.params.garden).then((settings) => {
        this.serviceContent = settings.serviceContent;
        this.openingStateSettings = settings.openingState;
        this.dateFormat = settings.dateFormat;

        this.setInfoIfOpen();
      });
    },
    setInfoIfOpen(){
      let today = new Date();
      this.currentDateText = this.formatDate(today);

      if (!this.openingStateSettings){
        this.setClosed();
        return;
      }

      if(this.checkIsNotWorkingDay(this.currentDateText)){
        return;
      }

      if (this.checkIsExceptionDay(this.currentDateText)){
        return;
      }

      if (this.checkInPeriods(today)){
        return;
      }

      this.setClosed();
    },

    checkIsNotWorkingDay(date){
      if(this.openingStateSettings.notWorkingDays == null){
        return false;
      }

      if(this.openingStateSettings.notWorkingDays.includes(date)){
        this.setClosed();
        return true;
      }

      return false;
    },

    checkIsExceptionDay(date){
      if(this.openingStateSettings.exceptionDays == null){
        return false;
      }

      let obj = this.openingStateSettings.exceptionDays;
      let flag = false;
      let exceptionDay = {};

      Object.keys(obj).forEach(function (key){
        if(date == obj[key].date){
          flag = true;
          exceptionDay = {
            hourFrom: obj[key].hourFrom,
            hourTo: obj[key].hourTo
          };
        }
      });

      if(flag){
        this.setOpened(exceptionDay.hourFrom, exceptionDay.hourTo);
      }

      return flag;
    },

    checkInPeriods(date){
      if(this.openingStateSettings.periods == null){
        return false;
      }

      let obj = this.openingStateSettings.periods;
      let dayName = this.getDayName(date);
      let flag = false;
      let period = {};

      Object.keys(obj).forEach(function (key){
        let dateFrom = new Date(obj[key].dateFrom);
        let dateTo = new Date(obj[key].dateTo);

        if(date >= dateFrom && date <= dateTo && obj[key].days.includes(dayName)){
          flag = true;
          period = {
            hourFrom: obj[key].hourFrom,
            hourTo: obj[key].hourTo
          }
        }
      });

      if(flag){
        this.setOpened(period.hourFrom, period.hourTo);
      }

      return flag;
    },

    formatDate(date){
      if(this.dateFormat=="USA"){
        return  String(date.getMonth() + 1).padStart(2, "0") + '/' + String(date.getDate()).padStart(2, "0") + '/' +  date.getFullYear();
      }else{
        return date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, "0") + '-' + String(date.getDate()).padStart(2, "0");
      }

    },

    formatOpeningHours(hourFrom, hourTo){
      return "(" + hourFrom + " - " + hourTo + ")";
    },

    getDayName(date){
      return date.toLocaleDateString('en-EN', { weekday: 'long' });
    },
    
    setOpened(hourFrom, hourTo){
      this.isItOpenTodayText = this.$t('openingHoursInfo.itsOpenToday');
      this.openingHoursTodayText = this.formatOpeningHours(hourFrom, hourTo);
    },

    setClosed(){
      this.isItOpenTodayText = this.$t('openingHoursInfo.itsClosedToday');
    },
    
  },watch: {
    '$i18n.messages': { //gdy tylko zmieni sie cokolwiek w tłumaczeniu to uruchom poniższa funkcje
      handler: function() {
        this.setInfoIfOpen();
      },
      deep: true,
      immediate: true
    }
  }
};

</script>
<style>

</style>