import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueParticles from "vue-particles";
import CoolLightBox from 'vue-cool-lightbox';
import VueScrollactive from 'vue-scrollactive';
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import '@fortawesome/fontawesome-free/css/all.css'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import './assets/scss/main.scss'
import VueLazyload from 'vue-lazyload'

import {i18n}  from './i18n/setup.js'
import VueEllipseProgress from 'vue-ellipse-progress';
Vue.use(VueEllipseProgress);
Vue.config.productionTip = false;
Vue.use(VueLazyload)
Vue.use(VueParticles);
Vue.use(CoolLightBox);
Vue.use(VueScrollactive);

// Vue.use(VueI18n)
// const messages = {
//     en: en,
//     pl: pl
// }

// const i18n = new VueI18n({
//     locale: 'en', // set locale
//     messages: messages, // set locale messages
// })
// const loadedLanguages = ['en'] // our default language that is preloaded

new Vue({
    router,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount('#app')
