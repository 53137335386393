<template>
  <div class="service-area pb--160" id="plan">
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="text-center section-title mb--30 mb_sm--0">
            <span class="subtitle2">{{ $t('headers-top-text.planAVisit')  }}</span>
            <h2 class="heading-title">{{ $t('headers.planAVisit')  }}</h2>
            <p class="description text-center">{{ $t('headers-bottom-text.planAVisit')  }}</p>
          </div>
        </v-col>
      </v-row>
      <v-row class=" service-main-wrapper">
        <!-- Start Single Service  -->
        <v-col
            lg="4"
            md="4"
            sm="6"
            cols="12"
            v-for="(service, i) in planAVisitContent"
            :key="i"
        >
          <div class="service service__style--2 text-center service-box">

              <div class="service">
                <div class="icon">
                  <img :src="service.src" />
                </div>
                <div class="content">
                  <h3 class="heading-title">{{ $t('planAVisit.' + service.id + '.title')  }}</h3>
                  <p v-html="$t('planAVisit.' + service.id + '.description')" />
                  <p v-if="service.hasDescriptionExtended"><a class="link_underline map_link" @click="sendEventToParent(service.hasDescriptionExtended, $t('planAVisit.' + service.id + '.titleExtended'), $t('planAVisit.' + service.id + '.descriptionExtended'))">{{ $t('buttons.seeMOreInfo')  }}</a></p>
                </div>
              </div>
          </div>
        </v-col>
        <!-- End Single Service  -->
      </v-row>
    </v-container>
  </div>
</template>
<script>
import feather from "feather-icons";
import {getLandingPageSettings} from "@/scripts/settings";
export default {
  data() {
    return {
      planAVisitContent: [],
    };
  },
  mounted() {
    this.fetchSettings();
  },
  methods: {
    sendEventToParent(hasDescriptionExtended, title, message){
      if(hasDescriptionExtended) this.$emit("openModal", title, message);
    },
    fetchSettings(){
      getLandingPageSettings(this.$route.params.garden).then((settings) => {
        this.planAVisitContent = settings.planAVisitContent;
      });
    },
  },
};
</script>
<style>

</style>