<template>
  <div class="youtube_video  pb--160" v-if="showYTVideo">
    <v-container>
      <v-row>
        <v-col lg="12">
          <div class="text-center section-title mb--60 mb_sm--0">
            <span class="subtitle2">{{ $t('headers-top-text.video')  }}</span>
            <h2 class="heading-title">{{ $t('youtubeVideo.header')  }}</h2>
            <p class="description text-center">{{ $t('headers-bottom-text.video')  }}</p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-center">
          <div v-html="$t('youtubeVideo.iframe')"></div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import {getLandingPageSettings} from "@/scripts/settings";

export default {
  components: {
  },
  data() {
    return {
      showYTVideo: false,
    };
  },
  mounted() {
    this.fetchSettings();
    if(this.$t('youtubeVideo.iframe') && this.$t('youtubeVideo.iframe')!='' && this.$t('youtubeVideo.iframe')!='youtubeVideo.iframe'){
      this.showYTVideo = true;
    }
  },
  methods: {
    fetchSettings(){
      getLandingPageSettings(this.$route.params.garden).then((settings) => {

      });
    },
  },
};
</script>