<template>
  <div class="rn-counterup-area pt--90 pt_sm--30 pt_md--50 pb--120 bg_color--1" v-if="counterUpContent.length">
    <v-container>
      <v-row>
        <!-- Start Single Counterup  -->
        <v-col
            md="3"
            sm="3"
            cols="6"
            v-for="(counter, i) in counterUpContent"
            :key="i">
          <div class="rn-counterup counterup_style--2 mt--30">
            <div class="icon">
              <img :src="counter.src" />
            </div>
            <h5 class="counter count">
              <VisibilitySensor @change="onChange">
                <countTo
                    :endVal="status ? counter.endVal : 0"
                    :autoplay="countUp"
                    :duration="500"
                    separator=" "
                ></countTo>
              </VisibilitySensor>
            </h5>
            <h4 class="description">
              {{ $t('counterUp.' + counter.id + '.description') }}
            </h4>
          </div>
        </v-col>
        <!-- Start Single Counterup  -->
      </v-row>
    </v-container>
  </div>
</template>
<script>
import VisibilitySensor from "vue-visibility-sensor";
import countTo from "vue-count-to";
import {getLandingPageSettings} from "@/scripts/settings";

export default {
  components: {
    countTo,
    VisibilitySensor,
  },
  data() {
    return {
      countUp: true,
      status: false,
      counterUpContent: [],
    };
  },
  mounted() {
    this.fetchSettings();
  },
  methods: {
    onChange(isVisible) {
      if (isVisible) {
        this.status = true;
      }
    },
    fetchSettings(){
      getLandingPageSettings(this.$route.params.garden).then((settings) => {
        this.counterUpContent = settings.counterUpContent;
      });
    },
  },
};
</script>