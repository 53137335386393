<template>
  <footer class="footer-area footer-style-01">
    <div class="im-call-to-action-area pb--70">
      <Contact />
    </div>
    <div class="footer-wrapper pb--70">
      <v-container class="container">
        <v-row class="pb--20">
          <v-col lg="3" md="6" sm="12" cols="12">
            <div class="footer-logo">
              <a href="/"><img src="/images/logo-gol-1line.svg" alt="Garden of Lights logo"/></a>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="4" md="6" sm="12" cols="12">
            <div class="footer-link">
              <h4>{{ $t('footer.address.header') }}</h4>
              <div v-html="$t('footer.address.content')" class="white--text"></div>
            </div>
            <div class="footer-link mt--40">
              <h4>{{ $t('footer.contact.header') }}</h4>
              <div v-html="$t('footer.contact.contentParagraph1')" class="white--text"></div>
            </div>
            <!--Right Side Buttons-->
            <SocialIconsBar />
          </v-col>
          <v-col lg="4" md="6" sm="12" cols="12" class="mt_mobile--40">
            <div class="footer-link">
              <h4>{{ $t('footer.menu.header') }}</h4>
              <div v-html="$t('footer.menu.content')" class="white--text"></div>
            </div>
            <div class="footer-link mt--40">
              <h4>{{ $t('footer.gardens.header') }}</h4>
              <div v-html="$t('footer.gardens.content')" class="white--text"></div>
            </div>
          </v-col>
          <v-col lg="4" md="6" sm="12" cols="12" class="mt_md--40 mt_sm--40 pl--0 pr--0">
            <div class="footer-link">
              <h4>{{ $t('footer.openingHours.header') }}</h4>
              <div v-html="$t('footer.openingHours.content')" class="white--text oppening-hours"></div>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt--20">
            <div class="white--text col-sm-12">
              Copyright © {{ new Date().getFullYear() }} {{ $t('footer.copyrightText') }}
            </div>
        </v-row>
      </v-container>
    </div>
  </footer>
</template>

<script>
  import Contact from "@/components/garden-of-lights/Contact.vue";
  import {getLandingPageSettings} from "@/scripts/settings";
  import SocialIconsBar from "@/components/garden-of-lights/SocialIconsBar.vue";

  export default {
    components: {SocialIconsBar, Contact},
    data() {
      return {
        logoFooter: null,
      };
    },
    created() {
      this.fetchSettings();
    },
    methods: {
      fetchSettings(){
        let landigpageName = "corporate";
        if (this.$route.params.landingpage == "poland") {
          landigpageName = "poland"
        }
        getLandingPageSettings(landigpageName).then((settings) => {
          this.logoFooter = settings.logo.logoFooter;
        });
      },
    },
  };
</script>
