<template>
  <div>
    <!-- Start Header Area -->
    <HeaderGardens />
    <!-- End Header Area -->

    <!-- Start Service Area  -->
    <ServiceGardens/>
    <!-- End Service Area  -->

    <!-- Start Portfolio Area -->
    <GardensInCountry />
    <!-- End Portfolio Area -->

    <!-- Start Footer Area  -->
    <FooterGardens id="contact" />
    <!-- End Footer Area  -->
  </div>
</template>

<script>
import FooterGardens from "@/components/garden-of-lights/FooterGardens.vue";
import {getLandingPageSettings, getSettings} from "@/scripts/settings";
import Portfolio from "@/components/garden-of-lights/Portfolio.vue";
import GardensInCountry from "@/components/garden-of-lights/GardensInCountry.vue";
import HeaderGardens from "@/components/garden-of-lights/HeaderGardens.vue";
import ServiceGardens from "@/components/garden-of-lights/ServiceGardens.vue";

export default {
  components: {
    ServiceGardens,
    HeaderGardens,
    GardensInCountry,
    Portfolio,
    FooterGardens
  },
  data() {
    return {
    };
  },
  mounted() {
    this.fetchSettings();
    //document.title = this.$t(`meta.title`);
  },
  methods: {
    fetchSettings(){
      let landigpageName = "corporate";
      if (this.$route.params.landingpage == "poland") {
        landigpageName = "poland"
      }
      getLandingPageSettings(landigpageName).then((settings) => {
        if (settings.gardensContent.length === 1) {
          this.$router.push('/' + settings.gardensContent[0].path);
        }
      });
    },
  },
};

</script>