<template>
  <div>
    <!-- Start sidebar mobile menu -->
    <v-navigation-drawer
        class="hidden-lg-and-up header-one-page-nav-drawer demo-page-drawer"
        v-model="drawer"
        fixed
        width="320">
      <v-list-item class="pa-5">
        <!--<div class="logo">
          <img v-if="$t('logo.logoDark')" :src="$t('logo.logoDark')" class="logo-dark" />
        </div>
        <v-spacer></v-spacer>-->
        <v-btn
            class="close-icon"
            icon
            @click="drawer = !drawer"
            v-html="iconSvg(closeIcon)">
        </v-btn>
      </v-list-item>
      <scrollactive
          active-class="v-btn--active"
          bezier-easing-value=".5,0,.35,1">
        <v-list>

          <v-list-item
              :ripple="false"
              key="plan"
              to="#plan"
              link
              class="scrollactive-item"
              v-if="activatePlanSection">
            <v-list-item-content>
              <v-list-item-title>{{ $t('nav.zaplanuj-wizyte') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              :ripple="false"
              key="map"
              to="#map"
              link
              class="scrollactive-item"
              v-if="activateMapSection">
            <v-list-item-content>
              <v-list-item-title>{{ $t('nav.mapa') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              :ripple="false"
              key="attractions"
              to="#attractions"
              link
              class="scrollactive-item"
              v-if="activateAttractionsSection">
            <v-list-item-content>
              <v-list-item-title>{{ $t('nav.atrakcje') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              :ripple="false"
              key="pricing"
              to="#pricing"
              link
              class="scrollactive-item"
              v-if="activatePricingSection">
            <v-list-item-content>
              <v-list-item-title>{{ $t('nav.cennik') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              :ripple="false"
              key="faq"
              to="#faq"
              link
              class="scrollactive-item"
              v-if="activateFAQSection"
              >
            <v-list-item-content>
              <v-list-item-title>{{ $t('nav.faq') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              :ripple="false"
              key="portfolio"
              to="#portfolio"
              link
              class="scrollactive-item"
              v-if="activateOtherGardensSection">
            <v-list-item-content>
              <v-list-item-title>{{ $t('nav.inne-wystawy') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              :ripple="false"
              key="contact"
              to="#contact"
              link
              class="scrollactive-item">
            <v-list-item-content>
              <v-list-item-title>{{ $t('nav.kontakt') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <Languages key="mobile" />

          <!-- End mobile menu sidebar item list -->
        </v-list>
      </scrollactive>
    </v-navigation-drawer>
    <!-- End sidebar mobile menu -->

    <!-- Start top header navbar -->
    <v-app-bar
        dark
        elevation="0"
        color="black"
        class="default-header">

      <div class="row header-location-pin-section mb_sm--10 mb_md--10 mb_lg--10">
        <div class="hidden-md-and-down col-md-0 col-lg-3 col-xl-3 col-0">
        </div>
        <div class="text-center  col-md-12 col-lg-6 col-xl-6 col-12">
          <img src="/images/pin-white.svg" /> {{ $t('header.pin_location') }}
        </div>
        <div class="hidden-md-and-down text-right col-md-0 col-lg-3 col-xl-3 col-0">
          <Languages class="hidden-xs-only hidden-sm-only hidden-md-only" key="desktop" />
        </div>
      </div>

      <a class="logo" @click="toTop" style="cursor: default">
        <img v-if="$t('logo.logoGOL')" :src="$t('logo.logoGOL')" class="logo-light" />
      </a>
      <!-- End brand logo -->
      <v-spacer></v-spacer>

      <!-- End mobile menu icon -->
      <v-toolbar-items class=" height-auto">
        <scrollactive
            active-class="v-btn--active"
            bezier-easing-value=".5,0,.35,1"
            :offset="71"
            class="hidden-xs-only hidden-sm-only hidden-md-only">
          <v-btn
              key="pricing"
              to="#pricing"
              link
              :ripple="false"
              text
              class="scrollactive-item"
              v-if="activatePricingSection"
          >{{ $t('nav.cennik') }}</v-btn>
          <v-btn
              key="plan"
              to="#plan"
              link
              :ripple="false"
              text
              class="scrollactive-item"
              v-if="activatePlanSection"
          >{{ $t('nav.zaplanuj-wizyte') }}</v-btn>
          <v-btn
              key="map"
              to="#map"
              link
              :ripple="false"
              text
              class="scrollactive-item"
              v-if="activateMapSection"
          >{{ $t('nav.mapa') }}</v-btn>
          <v-btn
              key="attractions"
              to="#attractions"
              link
              :ripple="false"
              text
              class="scrollactive-item"
              v-if="activateAttractionsSection"
          >{{ $t('nav.atrakcje') }}</v-btn>
          <v-btn
              key="faq"
              to="#faq"
              link
              :ripple="false"
              text
              class="scrollactive-item"
              v-if="activateFAQSection"
          >{{ $t('nav.faq') }}</v-btn>
          <v-btn
              key="portfolio"
              to="#portfolio"
              link
              :ripple="false"
              text
              class="scrollactive-item"
              v-if="activateOtherGardensSection"
          >{{ $t('nav.inne-wystawy') }}</v-btn>
          <v-btn
              key="contact"
              to="#contact"
              link
              :ripple="false"
              text
              class="scrollactive-item"
          >{{ $t('nav.kontakt') }}</v-btn>
        </scrollactive>
        <a class="own-btn own-btn-transparent ml--10" target="_blank" v-if="activateBuyTicketButtons" :href="$t('ctaUrls.buySingleTicket')">
          <span>{{ $t('buttons.cta') }}</span>
        </a>
      </v-toolbar-items>

      <v-btn
          icon
          :ripple="false"
          class="ma-0 pa-0 hidden-lg-and-up menu_icon"
          @click="drawer = !drawer"
          v-html="iconSvg(icon)">
      </v-btn>
      <!-- End header menu item -->
    </v-app-bar>
    <!-- End top header navbar -->

    <div class="info-section row pt--20 pb--20" v-if="activateInfoSection">
      <div class="hidden-md-and-down col-md-0 col-lg-2 col-xl-2 col-0">
      </div>
      <div class="col-md-12 col-lg-8 col-xl-8 col-12" style="display: flex;align-items: center;justify-content: center;">
        <img src="/images/info-icon.png" class="mr--10 ml--20" />
        <div v-if="$t('info.text')" v-html="$t('info.text')" class="text-justify pl--10 pr--20" />
      </div>
      <div class="hidden-md-and-down col-md-0 col-lg-2 col-xl-2 col-0">
      </div>
    </div>

  </div>
</template>

<script>
import feather from "feather-icons";
import Languages from "@/components/garden-of-lights/Languages.vue";
import {getLandingPageSettings} from "../../scripts/settings";

export default {
  components: {Languages},
  data() {
    return {
      drawer: false,
      icon: "menu",
      garden: this.$route.params.garden,
      closeIcon: "x",
      activateBuyTicketButtons: false,
      activateOtherGardensSection: true,
      activateMapSection: true,
      activateAttractionsSection: true,
      activatePricingSection: true,
      activateCounterSection: true,
      activateFAQSection: true,
      activatePlanSection: true,
      activateServiceSection: true,
      activateInfoSection: false,
      infoSectionSettings: null
    }
  },
  created() {
    this.fetchSettings();
  },
  methods: {
    fetchSettings(){
      getLandingPageSettings(this.$route.params.garden).then((settings) => {


        if (this.$t('logo.iconv')) {
          let link = document.createElement('link'),
              oldLink = document.getElementById('dynamic-favicon');
          link.id = 'dynamic-favicon';
          link.rel = 'shortcut icon';
          link.href = this.$t('logo.iconv');
          if (oldLink) {
            document.head.removeChild(oldLink);
          }
          document.head.appendChild(link);
        }

        this.isLoading = false;
        this.activateBuyTicketButtons = settings.activateBuyTicketButtons;
        this.activateOtherGardensSection = settings.activateOtherGardensSection;
        this.activateMapSection = settings.activateMapSection;
        this.activatePricingSection = settings.activatePricingSection;
        this.activateAttractionsSection = settings.activateAttractionsSection;
        this.activateCounterSection = settings.activateCounterSection;
        this.activateFAQSection = settings.activateFAQSection;
        this.activatePlanSection = settings.activatePlanSection;
        this.activateServiceSection = settings.activateServiceSection;
        this.infoSectionSettings = settings.infoSection;

        this.setInfoSection();
      });
    },
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    setInfoSection(){
      if(this.infoSectionSettings.active==true){
        var check = new Date();
        var from = new Date(this.infoSectionSettings.periodStart);
        var to   = new Date(this.infoSectionSettings.periodEnd);
        if(check >= from && check <= to){
          this.activateInfoSection = true;
        }
      }
    },
  },
};
</script>

<style >
.v-btn {
  text-transform: none;
}
</style>
