<template>
  <div>
    <!-- Start Header Area -->
    <HeaderCorporate />
    <!-- End Header Area -->

    <!-- Start 404 Page  -->

      <v-container class="mt--60 mb--120">
        <v-row>
          <v-col cols="12" class="text-center">
            <div class="inner">
              <h1 class="heading-title theme-gradient">404!</h1>
              <h3 class="sub-title">{{ $t('404.pageNotFound')  }}</h3>
              <span>{{ $t('404.pageNotFoundExplain')  }}</span>
              <div class="error-button mt--20">
                <router-link class="btn-default" to="/"
                >{{ $t('buttons.backToHomePage')  }}</router-link
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    <!-- End 404 Page  -->

    <!-- Start Footer Area  -->
    <FooterCorporate id="contact" />
    <!-- End Footer Area  -->
  </div>
</template>

<script>
import FooterCorporate from "@/components/garden-of-lights/FooterCorporate.vue";
import {getLandingPageSettings, getSettings} from "@/scripts/settings";
import GardensAll from "@/components/garden-of-lights/GardensAll.vue";
import HeaderCorporate from "@/components/garden-of-lights/HeaderCorporate.vue";

export default {
  components: {
    HeaderCorporate,
    GardensAll,
    FooterCorporate
  },
  data() {
    return {
    };
  },
  mounted() {
    this.fetchSettings();
    //document.title = this.$t(`meta.title`);
  },
  methods: {
    fetchSettings(){
      let landigpageName = "corporate";
      getLandingPageSettings(landigpageName).then((settings) => {
        if (settings.gardensContent.length === 1) {
          this.$router.push('/' + settings.gardensContent[0].path);
        }
      });
    },
  },
};

</script>