<template>
  <div v-if="languages && languages.length>1" class="mr--20">
    <div class="hidden-md-and-down">
      <a v-for="(lang, index) in languages" :value="lang" @click="changelanguage(lang)">
        <span v-if="language==lang" class="text-uppercase white--text font-weight-bold">{{lang}}</span>
        <span v-else class="text-uppercase white--text">{{lang}}</span>
        <span class="white--text" v-if="index != Object.keys(languages).length - 1"> | </span>
      </a>
    </div>
    <div class="mt--15 hidden-lg-and-up">
      <a v-for="(lang, index) in languages" :value="lang" @click="changelanguage(lang)">
        <span v-if="language==lang" class="text-uppercase black--text font-weight-bold">{{lang}}</span>
        <span v-else class="text-uppercase black--text">{{lang}}</span>
        <span class="black--text" v-if="index != Object.keys(languages).length - 1"> | </span>
      </a>
    </div>
  </div>
</template>
<script>
import {getLandingPageSettings, getSettings} from "../../scripts/settings";

export default {
  data() {
    return {
      languages: null,
      language: null,
    }
  },

  created() {
    // document.title = this.$t(`gardens.${this.$route.params.garden}.title`);
    this.fetchSettings();
  },
  methods: {
    changelanguage(language) {
      if(this.$route.params.lang === language){
        return;
      }
      let path;
      if (this.$route.fullPath === '/') {
        path = "/" + language;
      }else if(language ==="en" && this.$route.fullPath === '/'+this.$route.params.lang){
        path = "/"
      }else{
        path = this.$route.fullPath.replace(this.$route.params.lang, language);
      }

      let cookieBotScript = document.getElementsByClassName('cookiebot-script')[0];
      if (window.Cookiebot && cookieBotScript) {
        cookieBotScript.setAttribute('data-culture', language);
        if (!window.Cookiebot.hasResponse) {
          window.Cookiebot.renew();
        }
      }
      this.$router.push(path);
    },
    fetchSettings(){
        this.language = this.$route.params.lang;
      if (this.$route.params.garden) {
        getLandingPageSettings(this.$route.params.garden).then((settings) => {
          this.languages = settings.languages.available;
        }).catch((e)=>{
          this.languages = ['en']});
      }else{
        getLandingPageSettings('corporate').then((settings) => {
          this.languages = settings.languages.available;
        }).catch((e)=>{
          this.languages = ['en']});
      }
    },
  },watch: {
    '$route.params.lang': {
      handler: function(lang) {
        this.language = this.$route.params.lang
      },
      deep: true,
      immediate: true
    }
  }
};
</script>