//i18n-setup.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import store from '../store/storeLanguage.js'
import http from "axios";
Vue.use(VueI18n)

const messages = {
};
export const i18n = new VueI18n({
    locale: store.getters.getAppLanguage, // set locale
    fallbackLocale: 'en',
    messages // set locale messages
})


const loadedLanguages = [] // our default language that is preloaded

export function setI18nLanguage (lang) {
    i18n.locale = lang
    // $root.$i18n.locale
    axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
    store.commit('setAppLanguage', lang)
    return lang
}

export function loadLanguageAsync(lang, garden = false) {
    if (lang === null) {
        return  Promise.resolve();
    }
    let langDir = '/i18n/gardens/';
    if(garden === 'poland' || garden === 'corporate'){
        langDir = '/i18n/';
    }
    // If the same language
    if (i18n.locale === lang) {
        return fetch(`/i18n/${lang}.json`)
            .then(messageResponse => {
                        messageResponse.json().then((msg)=>{
                            if (garden) {
                                http.get(`${langDir}${garden}/${lang}.json`).then( resp => {
                                    // msg.gardens  =  {
                                    //     [`${garden}`]:resp.data
                                    // };
                                    if (resp.data && resp.data.meta && resp.data.meta.title) {
                                        if(document.title!=resp.data.meta.title)
                                            document.title = resp.data.meta.title;
                                    }
                                    i18n.setLocaleMessage(lang, {...msg,...resp.data});
                                    loadedLanguages.push(lang)
                                    return setI18nLanguage(lang)
                                });
                            }else{
                                i18n.setLocaleMessage(lang, msg)
                                loadedLanguages.push(lang)
                                return setI18nLanguage(lang)
                            }
                        })
            });
        return Promise.resolve(setI18nLanguage(lang))
    }

    // If the language was already loaded
    // if (loadedLanguages.includes(lang)) {
    //     return Promise.resolve(setI18nLanguage(lang))
    // }

    return fetch(`/i18n/${lang}.json`)
        .then(messageResponse => {
            messageResponse.json().then((msg)=>{
                if (garden) {
                    http.get(`${langDir}${garden}/${lang}.json`).then( resp => {
                        // msg.gardens  =  {
                        //     [`${garden}`]:resp.data
                        // };

                        if (resp.data && resp.data.meta && resp.data.meta.title) {
                            if(document.title!=resp.data.meta.title)
                                document.title = resp.data.meta.title;
                        }
                        i18n.setLocaleMessage(lang, {...msg,...resp.data});
                        loadedLanguages.push(lang)
                        return setI18nLanguage(lang)
                    });
                }else{
                    i18n.setLocaleMessage(lang, msg)
                    loadedLanguages.push(lang)
                    return setI18nLanguage(lang)
                }
            })
        });
    // If the language hasn't been loaded yet

}