<template>
  <div class="pb--160">
  <v-container>
  <v-row>
    <v-col lg="12">
      <div class="text-center section-title mb_sm--0">
        <span class="subtitle2">{{ $t('headers-top-text.testimonials')  }}</span>
        <h2 class="heading-title">{{ $t('headers.testimonials')  }}</h2>
        <p class="description text-center">{{ $t('headers-bottom-text.testimonials')  }}</p>
      </div>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <div class="pt--20 slick-space-gutter--15 slickdot--20 ">
        <VueSlickCarousel
            v-bind="settings"
            class="rn-slick-activation slick-dotted rn-slick-dot">
          <!-- Start Single Portfolio  -->
          <div
              class="single_im_portfolio"
              v-for="(item, i) in $t('testimonialContent')"
              :key="i">
            <div class="testimonial_card im_portfolio">
              <div class="">
                <div class="">
                  <div class="testimonial_stars">
                    <img src="/images/star.svg" />
                    <img src="/images/star.svg" />
                    <img src="/images/star.svg" />
                    <img src="/images/star.svg" />
                    <img src="/images/star.svg" />
                  </div>
                  <div class="testimonial_text">{{item.text}}</div>
                  <div class="testimonial_author">~ {{item.author}}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- End Single Portfolio  -->
        </VueSlickCarousel>
      </div>
    </v-col>
  </v-row>
  </v-container>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      // for all works
      settings: {
        dots: true,
        infinite: true,
        slidesToShow:3,
        slidesToScroll: 1,
        spaceBetween: 15,
        autoplay: true,
        responsive: [
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
              arrows: true,
            },
          },
          {
            breakpoint: 1263,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
              arrows: true,
            },
          },
          {
            breakpoint: 770,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1,
              arrows: true,
            },
          },
          {
            breakpoint: 599,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1,
              arrows: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
            },
          },
        ],
      },
    };
  },
};
</script>
