<template>
  <v-container>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
    <v-row class="align-center newsletter-area">
      <v-col xl="6" lg="10" md="12" sm="12" cols="12">
        <div class="inner">
          <h3 class="white--text mb--20">{{ $t('newsletter.title') }}</h3>
        </div>
        <div class="white--text mb--20">
          {{ $t('newsletter.desc') }}
        </div>
        <div v-if="success && !newsletterError">
          <span class="newsletterSuccesMsg" >{{$t('newsletter.successMesage')}}</span>
        </div>
        <ValidationProvider
            name="email"
            rules="required|email"
            v-slot="{ errors }"
            v-if="!success || newsletterError">
          <label>
            <input
                type="text"
                class=""
                rules="required|email"
                v-model="formData.email"
                :placeholder="$t('newsletter.yourEmail')" />
            <span class="inpur-error">{{ $t("newsletter.validation." + errors[0]) }}</span>
            <span class="inpur-error" v-if="newsletterError">{{ newsletterError }}</span>
          </label>
        </ValidationProvider>
        <ValidationProvider
            name="consent1"
            :rules="{ required: { allowFalse: false } }"
            v-slot="{ errors }"
            v-if="!success  || newsletterError">
          <label>
          <input
                type="checkbox"
                class=""
                v-model="formData.consent1"
            />
            <span v-html="$t('newsletter.consent1')" />
            <span class="inpur-error"  v-if="errors[0]">{{ $t("newsletter.validation." + errors[0]) }}</span>
            <span class="inpur-error"  v-else></span>
          </label>
        </ValidationProvider>
        <ValidationProvider
            name="consent2"
            :rules="{ required: { allowFalse: false } }"
            v-slot="{ errors }"
            v-if="!success  || newsletterError">
          <label>
          <input
              type="checkbox"
              class=""
              v-model="formData.consent2"
          />
            <span v-html="$t('newsletter.consent2')" />
            <span  class="inpur-error"  v-if="errors[0]">{{ $t("newsletter.validation." + errors[0]) }}</span>
            <span class="inpur-error"  v-else></span>
          </label>
        </ValidationProvider>
      </v-col>
      <v-col lg="2" offset-xl="2" md="12" sm="12" cols="12">
        <div class="text-left call-to-cation-tbn text-lg-right mt_lg--20 mt_md--20 mt_sm--20" v-if="!success  || newsletterError">
          <button class="own-btn own-btn-transparent ml--10" type="submit" value="submit">
            {{ $t('buttons.newsletterSave') }}
          </button>
        </div>
      </v-col>
    </v-row>
      </form>
    </ValidationObserver>
  </v-container>
</template>

<script>

  import http from "axios";
  import {getLandingPageSettings, getSettings} from "../../scripts/settings";
  import { ValidationObserver } from "vee-validate";
  import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
  import pl from "vee-validate/dist/locale/pl";

  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    data() {
      return {
        success: false,
        newsletterError: false,
        formData: {
          source: "www",
          email: "",
          consent1: "",
          consent2: "",
          language: "",
          garden: "",
        }
      };
    },
    methods: {
      onSubmit() {
        this.success = false;
        this.newsletterError = false;
        //wyslanie formularza po validacji

        if (this.$route.params.garden) {
          getLandingPageSettings(this.$route.params.garden).then((r) => {
            if(r.newsletter && r.newsletter.url){
              this.formData.language = this.$i18n.locale;
              this.formData.garden = this.$route.params.garden;

              http.post(r.newsletter.url, this.formData)
                  .then((response) => {
                    if (response.data.status) {
                      this.success = true;
                    }else{
                      this.newsletterError = this.$t('newsletter.error');
                    }
                  })
                  .catch((error) => {
                    this.newsletterError = this.$t('newsletter.error');
                  });
            }
          });
        }else{
          getLandingPageSettings('corporate').then((r) => {
            if(r.newsletter && r.newsletter.url){
              this.formData.language = this.$i18n.locale;
              this.formData.garden = this.$route.params.garden;

              http.post(r.newsletter.url, this.formData)
                  .then((response) => {
                    if (response.data.status) {
                      this.success = true;
                    }else{
                      this.newsletterError = this.$t('newsletter.error');
                    }
                  })
                  .catch((error) => {
                    this.newsletterError = this.$t('newsletter.error');
                  });
            }
          });
        }
      }
    },
  };
</script>

<style lang="scss">
  .form-wrapper label input,
  .form-wrapper label textarea {
    margin-bottom: 0;
  }
  .form-wrapper label {
    margin-bottom: 20px;
  }
  .newsletterSuccesMsg{
    color: #3EB75E
  }
</style>
