import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        appLanguage: sessionStorage.getItem("appLanguage") || process.env.VUE_APP_I18N_LOCALE || null //todo from settings
    },
    getters: {
        getAppLanguage: (state) => state.appLanguage
    },
    mutations: {
        setAppLanguage(state, language) {
            state.appLanguage = language;
            sessionStorage.setItem("appLanguage", language); // Whenever we change the appLanguage we save it to the localStorage
        }
    }
})