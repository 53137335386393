<template>
  <div class="portfolio-area pb--120 pt--20">
    <v-container>
      <v-row>
        <v-col lg="12" class="" >
          <div class="section-title mb--60 mt--40">
            <h2 v-html="$t('seeOurGardens')" class="text-center " />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <!-- Start Single Portfolio  -->
        <v-col
            lg="4"
            md="4"
            sm="6"
            cols="12"
            class="mb--40"
            v-for="(item, i) in gardensContent"
            :key="i">
          <div class="im_portfolio text-center">
            <div class="thumbnail_inner">
              <div class="thumbnail">
                <a :href="$t('gardensContent.'+ item.id + '.url')">
                  <img class="w-100" :src="$t('gardensContent.'+ item.id + '.src')" />
                </a>
              </div>
            </div>
            <div class="content">
              <div class="inner">
              </div>
            </div>
            <a class="transparent_link" :href="$t('gardensContent.'+ item.id + '.url')"></a>
          </div>
        </v-col>
        <!-- End Single Portfolio  -->
      </v-row>
    </v-container>
  </div>
</template>
<script>
import {getLandingPageSettings} from "@/scripts/settings";

export default {
  components: {
  },
  data() {
    return {
      gardensContent: null
    };
  },
  mounted() {
    this.fetchSettings();
  },
  methods: {
    getUrl(url){
      if(this.$route.params.lang){
        return "/" + this.$route.params.lang + url;
        return s;
      }
      return "/en"+url;
    },
    fetchSettings(){
      let landigpageName = "corporate";
      if (this.$route.params.landingpage == "poland") {
        landigpageName = "poland"
      }
      getLandingPageSettings(landigpageName).then((settings) => {
        this.gardensContent = settings.gardensContent;
      });
    },
  },
};
</script>