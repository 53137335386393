<template>
  <div>
    <v-app-bar
        dark
        color="transparent"
        fixed
        elevate-on-scroll
        class="header-one-page-nav">
      <a class="logo" @click="toTop">
        <img :src="logoDark" class="logo-dark" />
      </a>
      <v-spacer></v-spacer>
      <Languages />
    </v-app-bar>
    <!-- End top header navbar -->
  </div>
</template>

<script>
import feather from "feather-icons";
import Languages from "@/components/garden-of-lights/Languages.vue";
import { getLandingPageSettings} from "../../scripts/settings";

export default {
  components: {Languages},
  data() {
    return {
      isLoading: true,
      logoDark: null,
    }
  },
  computed: {
    items: vm => ([
    ]),
  },
  created() {
    this.fetchSettings();
  },
  methods: {
    fetchSettings(){
      let landigpageName = "corporate";
      if (this.$route.params.landingpage == "poland") {
        landigpageName = "poland"
      }
      getLandingPageSettings(landigpageName).then((settings) => {

        if (settings.logo.iconv) {
          let link = document.createElement('link'),
              oldLink = document.getElementById('dynamic-favicon');
          link.id = 'dynamic-favicon';
          link.rel = 'shortcut icon';
          link.href = settings.logo.iconv;
          if (oldLink) {
            document.head.removeChild(oldLink);
          }
          document.head.appendChild(link);
        }

        this.logoDark = settings.logo.logoDark;
          this.isLoading = false;
        });
    },
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style>
.v-btn {
  text-transform: none;
}
</style>
