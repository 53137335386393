<template>
  <div class="service-area creative-service-wrapper mt--120">
    <v-container>
      <v-row>
        <v-col class="text-center" >
          <img :src="logoHeadUrl" class="logo-header" />
        </v-col>
      </v-row>
      <v-row>
      <v-col lg="12" class="mt--30" >
          <div v-html="$t('missionGardensPoland.lead')" class="text-justify" />
          <div class="text-center">
            <a @click="showMoreDescClick()" v-if="showMoreDesc" class="btn-default btn-border mt--30">{{ $t('buttons.readMore')  }}</a>
          </div>
      </v-col>
      </v-row>
      <v-row v-if="!showMoreDesc">
        <v-col lg="12">
          <div class="description text-justify mt--30" v-html="$t('missionGardensPoland.restOfText') "></div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import {getLandingPageSettings} from "@/scripts/settings";

export default {
  data() {
    return {
      showMoreDesc: true,
      logoHeadUrl: null
    };
  },
  created() {
    this.fetchSettings();
  },
  methods: {
    showMoreDescClick(){
      this.showMoreDesc = !this.showMoreDesc;
    },
    fetchSettings(){
      getLandingPageSettings(this.$route.params.landingpage).then((settings) => {
        this.showMoreDesc = settings.leadShowMore;
        this.logoHeadUrl = settings.logo.logoHead;
      });
    }
  },watch: {
    '$i18n.messages': { //gdy tylko zmieni sie cokolwiek w tłumaczeniu to uruchom poniższa funkcje
      handler: function() {
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
<style>

</style>